<template>
  <div class="page-content">
    <div class="card row">
      <whatsapp/>
    </div>
  </div>
</template>
<script>
import whatsapp from '@/ui/components/create-template/Whatsapp.vue';

export default {
  name: 'CreateTemplate',
  metaInfo: {
    title: 'Create Template',
  },
  components: {
    whatsapp,
  },
};
</script>
