import api from '../library/axios';

export default {
  getList(query) {
    return api({
      method: 'get',
      url: '/api/group-member',
      params: query,
    });
  },
  create(data) {
    return api({
      method: 'post',
      url: '/api/group-member',
      data,
    });
  },
  delete(id) {
    return api({
      method: 'delete',
      url: `/api/group-member/${id}`,
    });
  },
  memberList(id, query) {
    return api({
      method: 'get',
      url: `/api/group-member/member/list/${id}`,
      params: query,
    });
  },
  memberRemove(data) {
    return api({
      method: 'delete',
      url: '/api/group-member/member/delete',
      data,
    });
  },
  memberAdd(data) {
    return api({
      method: 'post',
      url: '/api/group-member/member/add',
      data,
    });
  },
  nonMemberList(query) {
    return api({
      url: '/api/group-member/users-to-add',
      params: query,
    });
  },
  promoteDemoteAdmin(data, action) {
    return api({
      method: 'patch',
      url: `/api/group-member/member/${action}`,
      data,
    });
  },
  listForTemplates(query) {
    return api({
      url: '/api/group-member/group-for-templates',
      params: query,
    });
  },
  update(id, data) {
    return api({
      method: 'patch',
      url: `/api/group-member/${id}`,
      data,
    });
  },
};
